<template>
	<div class="detailCon" :class="!isMobile ? 'pcbox' : ''">
		<van-nav-bar v-if="isMobile" :title="content.title" left-arrow @click-left="onClickLeft" />
		<div class="conBox">
			<h3 class="title">{{content.title}}</h3>
			<div class="sourceTxt">{{content.source}}</div>
			<div class="detailBox" v-html="content.details"></div>
			<div class="googleBox" v-if="content.url">
				<a :href="content.url" download>
					<img :src="googleImg">
				</a>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,onBeforeMount,ref,watch,computed } from 'vue'
	import { useRoute,useRouter } from 'vue-router';
	import { getNewsDetail } from "@/api/index";
	import useConfigStore from '@/store/modules/config'
	
	const { proxy } = getCurrentInstance();
	const router = useRouter();
	const content = ref('')
	
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
	
	const lang = proxy.$i18n.locale
	const googleImg = require('@/assets/images/'+lang+'/google_download.png')
	
	onMounted(() => {
		const query = router.currentRoute.value.params
		getNewsDetail(query.id).then((res) => {
			content.value = res.data
		})
	})
	const onClickLeft = () => {
		history.back()
	}
</script>

<style lang="less" scoped>
	.pcbox{
		.conBox{
			max-width: 600px;margin: 0 auto;background: #fff;
			.title{
				margin-top: 0;font-size: 24px;padding-top: 40px;
			}
		}
	}
	.detailCon{
		width: 100%;height: calc(100vh - 2.5rem);overflow-y: scroll;
	}
	.conBox{
		width: 100%;padding: 0 0.75rem 0.75rem;box-sizing: border-box;
		.title{
			margin-top: 0.625rem;
		}
		.sourceTxt{
			font-size: 0.75rem;color: #999;margin-top: 0.5rem;
		}
		.detailBox{
			font-size: 0.8125rem;padding: 0.75rem 0;box-sizing: border-box;
			word-wrap: break-word;line-height: 1.125rem;
			/deep/ img{
				max-width: 100%;
			}
		}
		.googleBox{
			width: 100%;height: 3.125rem;background: #EEEEEE;border-radius: 0.875rem;
			margin: 0.625rem 0;
			a{
				width: 100%;height: 100%;padding: 0.875rem 0;box-sizing: border-box;
				display: flex;align-items: center;justify-content: center;
				img{
					height: 100%;
				}
			}
		}
	}
	
</style>